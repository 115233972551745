.title {
  color: #333;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin-top: 0;
}

.value {
  color: #333;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  margin: 0 0 24px 0;
}

.resendBtn {
  font-size: 14px;
  display: block;
  margin: 24px auto 0;
}

.submitBtn {
  margin-top: 24px;
}
