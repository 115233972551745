.title {
  text-align: center;
  margin: 0;
  color: #333;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.description {
  color: #333;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.checkInPassword {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.correctPhoneNumber {
  button {
    margin-left: 40px;
  }

  margin-bottom: 24px;
}

.phone {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}

.verifyBtn {
  margin-top: 24px;
}

.timeLeft {
  text-align: center;
  margin: 24px auto 0;
  font-size: 14px;
}

.resendBtn {
  font-size: 14px;
  display: block;
  margin: 24px auto 0;
}

.editPhoneBtn {
  font-size: 14px;
}
