.title {
  text-align: center;
  margin: 0;
  color: #333;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.phoneInput {
  margin-top: 24px;
}

.sendButton {
  margin-top: 4px;
}
