.dialogTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #333333;
  margin-bottom: 12px;
  text-align: center;
  margin: 0 0 25px;
}

.editBtn {
  cursor: pointer;

  &:hover {
    color: #bc6f87;
    border-color: #bc6f87;
  }
}

.labelTextArea {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
  margin: 0 0 8px 0;
}

.textArea {
  width: 100%;

  textarea {
    max-width: 100%;
    max-height: 290px;
    box-sizing: content-box;
    @media (max-width: 720px) {
      max-height: 250px;
    }
  }
}

.container {
  @media (max-width: 720px) {
    padding: 0 28px 0 12px;
  }
}