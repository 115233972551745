.title {
  text-align: center;
  margin: 0 0 24px 0;
  color: #333;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
.tabsContainer{
  display: flex;
  margin-bottom: 24px;
  justify-content: center;
}