.modalHeader {
  display: flex;
  align-items: center;
  padding: 18px 12px;

  .modalTitle {
    flex: 1;
    text-align: center;
    color: #333;
    font-size: 16px;
    font-weight: 700;
    line-height: 120%;
  }
}