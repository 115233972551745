.combinedOffersWrapper {
  background-color: #f4eae9;
  padding: 0 20px;

  @media (max-width: 720px) {
    padding: 0 12px;
  }
}

.combinedOffersContainer {
  max-width: 1760px;
  margin: 0 auto;
  padding: 80px 0;

  .title {
    text-align: center;
    margin-bottom: 32px;
  }

  .combinedCardsWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1454px) {
      flex-direction: column;
    }

    .combinedCardsContainer {
      background-color: #ffffff;
      border-radius: 20px;
      min-width: 690px;
      padding: 24px 10px;
      flex: 1;

      &:first-of-type {
        margin-right: 20px;
      }

      @media (max-width: 1454px) {
        &:first-of-type {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }

      .cardsWrapper {
        position: relative;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: nowrap;
        align-items: center;

        .imageContainer {
          position: relative;
          width: 100%;
          height: 405px;
          border-radius: 12px 12px 0 12px;

          .image {
            object-fit: cover;
            border-radius: 12px 12px 0px 0px;
            width: 100%;
            height: 100%;
          }
        }

        .cardContainer {
          width: 335px;
          height: 525px;
          position: relative;

          &:first-of-type {
            margin-right: 35px;
          }
        }

        .combinedIcon {
          position: absolute;
          left: 50%;
          top: 35%;
          transform: translate(-50%, -50%);
        }
      }

      .combinedCardsFooter {
        border-top: 1px solid #ebe4e4;
        padding: 24px 0 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        .priceWithoutDiscount {
          text-decoration: line-through;
          color: #999898;
          font-size: 16px;
          line-height: 120%;
          font-weight: 500;
          margin-right: 12px;
        }

        .priceWithDiscount {
          font-weight: 500;
          font-size: 24px;
          line-height: 120%;
          color: #ff5c32;
        }

        .addToCartBtn {
          margin-left: 32px;
          text-transform: initial;
          padding: 15px 44px;
        }
      }

      .discountIcon {
        position: absolute;
        top: 16px;
        left: 16px;
        background: #ffffff;
        border-radius: 100px;
        width: 43px;
        height: 43px;
        border: 1px solid #691b33;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        & > * {
          font-weight: 500;
          line-height: 100%;
          color: #691b33;
        }
      }
    }
  }
}
