@import '@layout/z-index-layouts.scss';

.cardLink {
  text-decoration: none;
  color: inherit;
  outline: none;
}

.cardContainer {
  min-width: 295px;
  max-width: 335px;
  position: relative;
  cursor: pointer;
  margin: 0 auto;
  width: 100%;

  @media (max-width: 720px) {
    min-width: 171px;
    max-width: calc(100% - 10px);
    margin-bottom: 10px;
  }

  &:hover {
    .imageContainer,
    .infoContainer {
      border-color: #691b33;
    }
  }

  &.disabled {
    user-select: none;
    opacity: 0.5;
  }

  .imageContainer {
    width: 100%;
    height: 357px;
    border-radius: 12px 12px 0 0;
    border-width: 1px 1px 0 1px;
    border-style: solid;
    border-color: #ebe4e4;
    background-color: #ffffff;
    position: relative;

    @media (max-width: 720px) {
      height: 207px;
      border-radius: 8px 8px 0px 0px;
    }

    .image {
      object-fit: cover;
      border-radius: 12px 12px 0px 0px;

      @media (max-width: 720px) {
        border-radius: 8px 8px 0px 0px;
      }
    }

    .slickDotsContainer {
      width: 100%;
      height: auto;
      bottom: 10px;
      margin: 0;
      list-style: none;
      text-align: center;
      position: absolute;
      display: block;
      padding: 0;

      li {
        position: relative;
        display: inline-block;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
      }

      li button {
        font-size: 0;
        line-height: 0;
        display: block;
        cursor: pointer;
        color: transparent;
        border: 0;
        margin: 0;
        padding: 0;
        border-radius: 50%;
        width: 7px;
        height: 7px;
        background-color: #ffffff;
      }

      li[class='slick-active'] button {
        width: 10px;
        height: 10px;
        background-color: #691b33;
      }

      li button:focus,
      li button:hover {
        outline: 0;
      }
    }
  }

  .infoContainer {
    height: 204px;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #ebe4e4;
    border-radius: 0px 0px 12px 12px;
    background-color: #ffffff;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: 720px) {
      border-radius: 0px 0px 8px 8px;
      height: 162px;
    }

    & > div:first-of-type {
      border-top: 1px solid #ebe4e4;
    }
  }

  .cardTitle {
    padding: 12px 12px 0 12px;
  }

  .cardCountry {
    padding: 0 12px;
    display: flex;
    align-items: center;
  }

  .cardFooter {
    width: 100%;
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 720px) {
      padding: 6px 0;
    }

    .cardFooterPrice {
      margin-left: 12px;

      padding: 12px 0;

      @media (max-width: 720px) {
        padding: 8px 0;
        margin-left: 6px;
        white-space: nowrap;
      }
    }
  }
}

.highlightIcon {
  left: 20px;
  top: 20px;
  @media (max-width: 720px) {
    top: 10px;
    left: 10px;
  }
}

.swiper {
  height: 100%;
}