.offersWrapper {
  padding: 0 20px;

  @media (max-width: 720px) {
    padding: 0;
  }

  .offersContainer {
    max-width: 1760px;
    margin: 0 auto;
  }
}

.offersTitle {
  margin: 0;
  color: #292933;
  font-size: 24px;
  font-weight: 700;
  line-height: 120%;

  @media (max-width: 960px) {
    font-size: 20px;
    margin-right: 32px;
  }
}

.tabsListContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 720px) {
    padding: 0 12px;
  }
}

.tabsContainer {
  @media (max-width: 720px) {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    & > * {
      flex-shrink: 0;
      min-width: 50px;
    }
  }
}

.tabsList {
  margin-left: 32px;
  @media (max-width: 960px) {
    overflow: hidden;
    margin-left: 0;
    font-size: 14px;
  }
}

.tabContainer {
  width: 100%;
  margin: 32px auto 0;
  padding: 0 6px;
  overflow: hidden;
}

.productCardContainer {
  @media (max-width: 720px) {
    min-width: initial;
    max-width: initial;
    width: calc(100% - 12px);

    &:nth-child(odd) {
      margin: 0 auto 6px;
    }
  }
}

.showAllBtn {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  border-radius: 6px;
  border: 2px solid #5f6eb2;
  padding: 13px 44px;
  background-color: transparent;
  text-transform: initial;
  margin: 0 auto;
  width: fit-content;
  display: block;
  text-align: center;

  @media (max-width: 720px) {
    width: calc(100% - 24px);
  }

  &:hover {
    border-color: #7983b0;
  }

  &:active {
    border-color: #404e91;
  }

  &:focus {
    text-decoration: none;
  }
}

.productCardMargin {
  margin-right: 16px;
}
